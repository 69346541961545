<template>
  <div>
    <a-form :form="form" layout="inline">
      <div class="table-style-product">
        <div class="title">
          <span>{{ id === "0" ? "会员录入" : "会员编辑" }}</span>
        </div>
        <div style="display: flex">
          <a-card
            title="个人头像"
            style="margin: 10px 0 0 10px; width: 130px; height: 165px"
            size="small"
          >
            <a-form-item class="items">
              <div class="clearfix pic-box">
                <a-upload
                  :multiple="true"
                  :action="actions"
                  listType="picture-card"
                  :fileList="fileList"
                  @preview="handlePreview"
                  @change="picChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="handleCancel"
                >
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-card>
          <div>
            <a-card title="个人信息" style="margin: 10px" size="small">
              <div class="form-cont">
                <a-row>
                  <!-- <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <a-form-item label="用户类型">
                <a-select
                  placeholder="请选择用户类型"
                  style="width: 280px"
                  v-decorator="['admin',{rules: [{ required: true, message: '请选择用户类型' }], initialValue: vipInfo.admin}]"
                  @change="adminChange"
                >
                  <a-select-option :value="0">普通用户</a-select-option>
                  <a-select-option :value="100">管理员</a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="用户身份">
                      <a-select
                        placeholder="请选择用户身份"
                        style="width: 280px"
                        v-decorator="[
                          'type',
                          {
                            rules: [
                              { required: true, message: '请选择用户身份' }
                            ],
                            initialValue: vipInfo.type ? vipInfo.type : vipType
                          }
                        ]"
                        @change="handleChange"
                      >
                        <a-select-option value="TEACHER">老师</a-select-option>
                        <a-select-option value="STUDENT" v-if="vipAdmin === 0"
                          >学生及家长</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="用户姓名" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入名称"
                        v-decorator="[
                          'name',
                          {
                            rules: [{ required: true, message: '请输入名称' }],
                            initialValue: vipInfo.name
                          }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row v-if="vipType !== 'TEACHER'">
                  <!-- 英文姓名 -->
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="英文姓名" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入英文名称"
                        v-decorator="[
                          'nameForeign',
                          { initialValue: vipInfo.nameForeign }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="国际地区" class="form-item-label items">
                      <a-select
                        style="width:280px"
                        placeholder="请选择国际地区"
                        v-decorator="[
                          'country',
                          {
                            rules: [
                              { required: true, message: '请选择国际地区' }
                            ],
                            initialValue: vipInfo.country
                          }
                        ]"
                      >
                        <a-select-option
                          :value="item.label"
                          v-for="(item, index) in areas"
                          :key="index"
                        >
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row v-if="vipType !== 'TEACHER'">
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="入学年份" class="form-item-label items">
                      <a-select
                        style="width:280px"
                        placeholder="请选择入学年份"
                        v-decorator="[
                          'intoYear',
                          {
                            rules: [
                              { required: true, message: '请选择用户身份' }
                            ],
                            initialValue: formatYear(vipInfo.intoYear)
                          }
                        ]"
                      >
                        <a-select-option
                          :value="item.id"
                          v-for="(item, index) in entranceYear"
                          :key="index"
                        >
                          {{ item.text }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="班级" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入班级"
                        v-decorator="[
                          'team',
                          {
                            rules: [{ required: true, message: '请输入班级' }],
                            initialValue: vipInfo.team
                          }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="手机号" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入手机号"
                        v-decorator="[
                          'phone',
                          {
                            rules: [
                              { required: true, message: '请输入手机号' }
                            ],
                            initialValue: vipInfo.phone
                          }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item
                      :label="vipType === 'TEACHER' ? '教师工号' : '学生编号'"
                      class="form-item-label items"
                    >
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        :placeholder="
                          vipType === 'TEACHER' ? '请输入工号' : '请输入学生编号'
                        "
                        v-decorator="[
                          'no',
                          {
                            rules: [{ required: true, message: '此为必填项' }],
                            initialValue: vipInfo.no
                          }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row v-show="vipType === 'TEACHER'">
                  <!-- <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-show="vipType === 'TEACHER'">
              <a-form-item label="教师职务" class="form-item-label items">
                <a-input
                  class="unityWidth"
                  style="width: 280px"
                  placeholder="请输入职务"
                  v-decorator="['duty',{ initialValue: vipInfo.duty}]"
                ></a-input>
              </a-form-item>
            </a-col> -->
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="职务名称" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入职务名称"
                        v-decorator="[
                          'dutyName',
                          { initialValue: vipInfo.dutyName }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <!--  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <a-form-item label="选择学校">
                <a-select
                  :disabled="!!schoolId"
                  placeholder="请选择学校"
                  style="width: 280px"
                  v-decorator="[
                    'schoolId',
                    { rules: [{ required: true, message: '请选择学校' }], initialValue: vipInfo.schoolId }
                  ]"
                >
                  <a-select-option v-for="blocs in schoolList" :key="blocs.id" :value="blocs.id">{{
                    blocs.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
                </a-row>
                <a-row v-show="vipType === 'TEACHER'">
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="部门名称" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入部门名称"
                        v-decorator="[
                          'depName',
                          { initialValue: vipInfo.depName }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="职位名称" class="form-item-label items">
                      <a-input
                        class="unityWidth"
                        style="width: 280px"
                        placeholder="请输入职位名称"
                        v-decorator="[
                          'positionName',
                          { initialValue: vipInfo.positionName }
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row v-show="vipType !== 'TEACHER'">
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="备注">
                      <template>
                        <a-textarea
                          allow-clear
                          placeholder="请填写备注信息, 注意字数不要超过500字哦~"
                          :rows="4"
                          style="resize: none;width:280px;font-size: 12px;"
                          @input="limitChange"
                          v-model="vipInfo.remark"
                        />
                        <p class="wordLimit">{{ wordLimit }}/500</p>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </a-card>
            <a-card title="其它配置" style="margin: 10px" size="small" v-if="this.id === '0'">
              <div class="form-cont">
                <a-row>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="发送短信"
                      ><a-switch
                        :checked="sendText"
                        checked-children="开"
                        un-checked-children="关"
                        @change="sendText = !sendText"
                    /></a-form-item>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <a-form-item label="短信模板" v-if="sendText">
                      <a-select v-model="smsCode" style="width: 200px">
                        <a-select-option
                          v-for="tmplt in smsTemplateList"
                          :key="tmplt.code"
                          :value="tmplt.code"
                          >{{ tmplt.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-form-item label="效果展示" v-if="sendText">
                    <a-card style="width: 616px" v-if="smsCode">
                      <span>{{
                        smsTemplateList.find(tmplt => {
                          return tmplt.code === smsCode;
                        }).content
                      }}</span>
                    </a-card>
                  </a-form-item>
                </a-row>
              </div>
            </a-card>
          </div>
        </div>
      </div>
      <div class="footer-btn-bottom">
        <a-button type="primary" @click="next" class="mr10"
          >确认并保存</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import { areas } from "../../utils/area";
import { addMyVip, modifyVip, getVipInfo, getSmsTemplateList } from "../../serve/member";
import { getSchool } from "@/serve/open";
import global from "global";
export default {
  name: "addVip",
  data() {
    return {
      wordLimit: 0,
      form: this.$form.createForm(this),
      id: this.$route.params.id || "0",
      areas: [],
      schoolId: 0,
      previewVisible: false,
      fileList: [],
      content: "",
      previewImage: "",
      options: [],
      vipInfo: {},
      vipType: "TEACHER",
      vipAdmin: 0,
      schoolList: [], // 学校列表
      entranceYear: [], // 年份列表
      smsTemplateList: [],
      smsCode: "",
      sendText: true
    };
  },
  watch: {
    vipInfo: {
      handler(newVal) {
        if (newVal.remark) {
          this.wordLimit = newVal.remark.length;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 字数限制
    limitChange() {
      let length = this.vipInfo.remark.length;
      if (length > 500) {
        this.vipInfo.remark = this.vipInfo.remark.substring(0, length - 1);
        return this.$message.error("字数不能超过500哦~");
      } else {
        this.wordLimit = length;
      }
    },
    // 入学年份
    formatYear(year) {
      if (year) {
        let index = year.indexOf("-");
        if (index > -1) {
          return year.slice(0, index);
        }
        return year;
      }
    },
    // 年份列表
    creationYear() {
      let temp = [...new Array(new Date().getFullYear() + 2).keys()].slice(
        new Date().getFullYear() - 20
      );
      temp.reverse().forEach(item => {
        this.entranceYear.push({
          id: item,
          text: item
        });
      });
    },
    // 选择用户身份
    handleChange(value) {
      this.smsTemplateList = [];
      this.smsCode = "";
      this.vipType = value;
      this.getSmsTemplateList();
    },
    // 选择用户类型
    /* adminChange (val) {
      this.vipAdmin = val
    }, */
    // 获取照片
    getPhoto(id) {
      return global.file.url + "/sys/fs/" + id;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    picChange({ fileList }) {
      this.fileList = fileList;
    },
    // 下一步
    next(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // if (this.id === '0') {
          //   let picList = this.fileList[0].response.id
          //   values.headPic = picList
          // } else {
          //   values.headPic = this.vipInfo.pic
          // }
          values.phone = values.phone.trim();
          if (!/^1[0-9]{10}$/.test(values.phone)) {
            return this.$message.error("请输入正确格式的手机号");
          }
          // 照片
          if (this.fileList.length === 0) {
            values.headPic = "";
          } else if (this.fileList[0].response) {
            values.headPic = this.fileList[0].response.id;
          } else {
            values.headPic = this.vipInfo.headPic;
          }
          if (this.vipInfo.remark) {
            values.remark = this.vipInfo.remark;
          } else {
            values.remark = "";
          }
          values.smsCode = this.smsCode;
          values.admin = 0;
          if (this.id === "0") {
            addMyVip(0, values)
              .then(res => {
                this.$message.success("录入成功");
                this.form.resetFields();
                this.fileList = [];
                if (this.vipType === "TEACHER") {
                  this.$router.push({ path: "/teacherList" });
                } else {
                  this.$router.push({ path: "/studentList" });
                }
              })
              .catch(err => {
                this.$message.error(err.data.message || "操作失败");
              });
          } else {
            modifyVip(0, this.id, values)
              .then(res => {
                this.$message.success("修改成功");
                if (this.vipType === "TEACHER") {
                  this.$router.push({ path: "/teacherList" });
                } else {
                  this.$router.push({ path: "/studentList" });
                }
              })
              .catch(err => {
                this.$message.error(err.data.message || "操作失败");
              });
          }
        }
      });
    },
    get() {
      if (this.id !== "0") {
        getVipInfo(this.schoolId, this.id).then(res => {
          this.vipInfo = res;
          this.vipType = res.type;
          console.log("vipInfo", this.vipInfo);
          if (res.headPic) {
            this.fileList.push({
              uid: -1,
              name: "photo.jpg",
              status: "done",
              url: this.getPhoto(res.headPic)
            });
          }
        });
      }
    },
    getSchoolList() {
      getSchool().then(res => {
        this.schoolList = res;
      });
    },
    getSmsTemplateList() {
      getSmsTemplateList(this.schoolId).then(res => {
        this.smsTemplateList = res.filter(tmplt => {
          if (this.vipType === "TEACHER") {
            return tmplt.code === "SMS_NEW_SCHOOL_TEACHER";
          } else if (this.vipType==="STUDENT") {
            return tmplt.code === "SMS_NEW_SCHOOL_STUDENT";
          }
        });
      });
    }
  },
  computed: {
    actions() {
      return global.file.url + "/sys/fs/";
    }
  },
  created() {
    this.areas = areas;
    this.get();
    this.creationYear();
    // this.getSchoolList() // 获取学校列表
    this.getSmsTemplateList();
  }
};
</script>

<style lang="less">
.table-style-product {
  border: 1px solid #ccc;
  .title {
    line-height: 40px;
    padding: 0 16px;
    font-size: 16px;
    background: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }
  .form-cont {
    .wordLimit {
      font-size: 11px;
      text-align: right;
      margin: 0;
      height: auto;
      line-height: 15px;
    }
    > .ant-row {
      padding: 10px 0 0 0;
      /* .form-item-label{
          .ant-form-item-label{
            border-right: 1px solid #ccc;
            margin-right: 6px;
          }
        } */
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-form-item-label label {
        font-weight: bold;
      }
    }
    .ant-form-item-label {
      width: 120px;
    }
    .core-label > .ant-form-item-label {
      width: 80px;
    }
  }

  .children-items .ant-form-item-label {
    width: 80px;
  }
  .pic-box > span {
    display: flex;
  }
}
.spaces-box .ant-form-item-control-wrapper {
  width: 80%;
}
.footer-btn-bottom {
  text-align: center;
  margin: 20px 0;
}
</style>
